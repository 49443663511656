import { AbstractControl, ValidatorFn } from '@angular/forms';
import {
  ChartType,
  GaugeChartModel,
  HeartRateVariability,
  LicenseTypes,
  MAIN_ACCENT_COLOR,
  OsTypes,
  PeersChartModel,
  RidesChartSeriesModel,
  RideTypes,
  StatsModel,
  TelemetryModel
} from '@carol-nx/data';

export const verboseLicenseTypes = (licenseType: LicenseTypes): string => {
  switch (licenseType) {
    case LicenseTypes.PERSONAL:
      return 'Personal';
    case LicenseTypes.COMPANY:
      return 'Company';
    case LicenseTypes.GYM:
      return 'Gym';
    case LicenseTypes.COMMERCIAL_EC:
      return 'Commercial EC';
  }
};

export const setInLocalStorage = (data: any) => {
  for (const k in data) {
    if (data.hasOwnProperty(k)) {
      localStorage.setItem(k, data[k]);
    }
  }
};

export const setInSessionStorage = (data: any) => {
  for (const k in data) {
    if (data.hasOwnProperty(k)) {
      sessionStorage.setItem(k, data[k]);
    }
  }
};

export const saveTokensToStorage = (data: any) => {
  if (JSON.parse(localStorage.getItem('rememberMe'))) {
    setInLocalStorage(data);
  } else {
    let localData = { ...data };
    delete localData.refreshToken;
    delete localData.refreshTokenExpiresAt;
    setInLocalStorage(localData);
    const sessionData = { refreshToken: data.refreshToken, refreshTokenExpiresAt: data.refreshTokenExpiresAt };
    setInSessionStorage(sessionData);
  }
};

const messageDictionary = {
  'rider.nickname.exists': 'Nickname already exists',
  'booking.create.conflict': 'Conflict when creating a booking',
  'booking.busy': 'Booking is busy',
  'friend.invite.conflict': 'Conflict when inviting a friend',
  'rider.register.conflict': 'Conflict when registration a rider',
  'rider.save.conflict': 'Rider save conflict',
  'ride.save.conflict': 'Ride save conflict',
  'license.forbidden': 'Membership access denied',
  'token.generate.forbidden': 'token cannot be generated', // Fast registration user
  'friend.invite.forbidden': 'Forbidden invitation', // Invited myself
  'rider.password.notmatch': 'Incorrect password',
  'rider.confirm.alreadyactive': 'Registration was confirmed earlier',
  'rider.confirm.codenotvalid': 'Verification code is not valid',
  'booking.forbidden': 'Denying access to this booking',
  'file.cannotload': 'File upload error',
  'serial.notallowed': 'Operation not applicable to this serial number',
  'type.notsupported': 'Unsupported type',
  'time.incorrect': 'Incorrect time',
  'rider.avatar.empty': 'Avatar file is empty',
  'rider.credentials.empty': 'No credentials',
  'bodyandrequest.notequal': 'Request and body id are not equal',
  'ride.notfound': 'Ride not found',
  'country.notfound': 'Country not found',
  'license.notfound': 'Membership not found',
  'rider.notfound': 'Rider not found',
  'friend.notfound': 'Friend not found',
  'booking.notfound': 'Booking not found',
  'bike.notfound': 'Bike not found',
  'auth.credential.invalid': 'Bad credentials',
  'auth.token.malformed': 'Token corrupted',
  'bike.unauthorized': 'Bicycle Membership holder authorization required',
  'auth.token.invalid': 'The current token is invalid',
  'auth.rider.notfound': 'Authorization is not possible. Rider not found.',
  'auth.admin.notfound': 'Authorization is not possible. Admin not found.',
  'auth.account.locked': 'Account not activated',
  'property.notfound': 'Property not found',
  'argument.list.incorrect': 'Argument list is incorrect',
  'argument.incorrect': 'One of the arguments is not correct',
  'value.invalid': 'The value is not acceptable',
  'password.reset.expired': 'This link is expired, please request a new one',
  'roles.conflict': 'Roles conflict',
  'file.format': 'File format error',
  'number.format': 'Number format violation',
  'no.field': 'No field',
  'null.list.value': 'The list contains a null object',
  'auth.refreshtoken.invalid': 'Invalid refresh token',
  'password.changed.refreshtoken.invalid': 'The password has changed. The token is not valid',
  'type.notallowed': 'Ride type not allowed in request',
  'uuid.invalid': 'UUID value is invalid'
};

export const verboseErrorMessage = (message: string): string => {
  if (messageDictionary.hasOwnProperty(message)) {
    return messageDictionary[message];
  } else if (message && message.length) {
    return message && `${message[0].toUpperCase()}${message.substring(1).toLocaleLowerCase()}`.replace('_', ' ');
  } else {
    return JSON.stringify(message);
  }
};

export const getNeededColumnsByRideType = (rideType: RideTypes): string[] => {
  switch (rideType) {
    case RideTypes.REHIT:
    case RideTypes.IntenseRides:
    case RideTypes.Intermediate3x15:
    case RideTypes.IntermediateRides:
    case RideTypes.Energiser3x10:
    case RideTypes.EnergiserRides:
      return ['sequential', 'start', 'fitnessScore', 'calories', 'peakPower', 'totalPower', 'heartRateMax', 'resistanceChange'];
    case RideTypes.RapidFatBurnRides:
    case RideTypes.RapidFatBurn30Rides:
    case RideTypes.RapidFatBurn45Rides:
    case RideTypes.RapidFatBurn60Rides:
      return ['sequential', 'start', 'calories', 'peakPower', 'totalPower', 'heartRateMax'];
    case RideTypes.FreeCustomRides:
    case RideTypes.FreeRides:
    case RideTypes.DrAdamsonIntervals:
    case RideTypes.GibalaIntervalsRides:
    case RideTypes.CoyleIntervalsRides:
    case RideTypes.NorwegianZoneIntervals:
      return ['sequential', 'start', 'calories', 'averagePower', 'totalPower', 'duration'];
    case RideTypes.FitnessTestsRides:
      return ['sequential', 'start', 'calories', 'peakPower', 'totalPower', 'duration', 'heartRateMax'];
  }
};

export const buildPagingObject = (response) => {
  const paging = {};
  for (const k in response) {
    if (response.hasOwnProperty(k)) {
      if (k !== 'content' && k !== 'AppVersion' && k !== 'status') {
        paging[k] = response[k];
      }
    }
  }
  return paging;
};

export const emailRegex = /^([a-zA-Z0-9_\-.+]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/;

export const usernameRegex = /^.+$/;

export const decimalPattern = /^[-+]?\d+(,\d+)*((\.\d+)?([eE][-+]?\d+)?)?$/;

export function fileExtensionValidator(validExt: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    let forbidden = true;
    if (control.value && control.value._fileNames) {
      const fileName = control.value._fileNames;
      const fileExt = fileName.split('.').pop();
      validExt.split(',').forEach(ext => {
        if (ext.trim() === fileExt) {
          forbidden = false;
        }
      });
    }
    return forbidden ? { inValidExt: true } : null;
  };
}

export function enumKeys<O extends object, K extends keyof O = keyof O>(obj: O): K[] {
  return Object.keys(obj).filter(k => Number.isNaN(+k)) as K[];
}

export function getCurrentTimeZone(): string {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

export function isImperial() {
  const currentTimezoneId = getCurrentTimeZone();
  const imperialZone = ['Asia/Yangon',
    'Africa/Monrovia',
    'Europe/London',
    'America/New_York',
    'America/Detroit',
    'America/Kentucky/Louisville',
    'America/Kentucky/Monticello',
    'America/Indiana/Indianapolis',
    'America/Indiana/Vincennes',
    'America/Indiana/Winamac',
    'America/Indiana/Marengo',
    'America/Indiana/Petersburg',
    'America/Indiana/Vevay',
    'America/Chicago',
    'America/Indiana/Tell_City',
    'America/Indiana/Knox',
    'America/Menominee',
    'America/North_Dakota/Center',
    'America/North_Dakota/New_Salem',
    'America/North_Dakota/Beulah',
    'America/Denver',
    'America/Boise',
    'America/Phoenix',
    'America/Los_Angeles',
    'America/Anchorage',
    'America/Juneau',
    'America/Sitka',
    'America/Metlakatla',
    'America/Yakutat',
    'America/Nome',
    'America/Adak',
    'Asia/Tbilisi',
    'Pacific/Honolulu'];
  return imperialZone.includes(currentTimezoneId);
}

export function getLocale(): string {
  return isImperial() ? 'en-US' : 'de-DE';
}

export function roundByPrecision(val: number, precision: number) {
  return Math.round(val * Math.pow(10, precision)) / Math.pow(10, precision);
}

export const isValidChartData = (chartData: GaugeChartModel): boolean => {
  return !chartData.validSubscription || !!chartData.last;
};

export const pluralize = (count: number, noun: string, suffix = 's') =>
  `${count} ${noun}${count !== 1 ? suffix : ''}`;

export const blobToFile = (theBlob: Blob, fileName: string): File => {
  let b: any = theBlob;

  //A Blob() is almost a File() - it's just missing the two properties below which we will add
  b.lastModified = new Date();
  b.name = fileName;

  //Cast to a File() type
  return <File>theBlob;
};

export const getMobileOS = (): OsTypes => {
  if (navigator.userAgent.indexOf('Win') != -1) return OsTypes.windows;
  if (navigator.userAgent.indexOf('Mac') != -1) return OsTypes.mac;
  if (navigator.userAgent.indexOf('Linux') != -1) return OsTypes.linux;
  if (navigator.userAgent.indexOf('Android') != -1) return OsTypes.android;
  if (navigator.userAgent.indexOf('like Mac') != -1) return OsTypes.ios;

  return OsTypes.unknown;
};

export const isOnMobileDevice = (): boolean => {
  // @ts-ignore
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  return (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent));
};

export const riderStatsToRidesPerWeekChart = (stats: StatsModel): GaugeChartModel => ({
  last: stats.ridesPerWeek.ridesPerWeekString,
  lastFull: stats.ridesPerWeek.ridesPerWeek,
  weekCount: stats.ridesPerWeek.weekCount,
  target: stats.ridesPerWeek.target,
  base: String(stats.ridesPerWeek.target),
  arcMin: stats.ridesPerWeek.arcMin,
  starCount: stats.ridesPerWeek.starCount,
  arcMax: stats.ridesPerWeek.arcMax,
  arcMaxDouble: stats.ridesPerWeek.arcMaxDouble,
  validSubscription: stats.ridesPerWeek.validSubscription,
  chartType: ChartType.RidesPerWeek,
  title: 'DASHBOARD_INFO_ACTIVITY_RIDES_PER_WEEK'
} as GaugeChartModel);

export const buildTelemetryData = (telemetryArray: TelemetryModel[] | HeartRateVariability[], neededValueKeys: string[]): RidesChartSeriesModel[] => {
  const xAxis = neededValueKeys[neededValueKeys.length - 1];
  const result: RidesChartSeriesModel[] = [];
  const keyMap = new Map<string, RidesChartSeriesModel>();
  const dataLength = telemetryArray.length;
  for (const neededValueKey of neededValueKeys) {
    if (neededValueKey !== xAxis && telemetryArray.length) {
      const dataForKey: RidesChartSeriesModel = { data: new Array(dataLength), name: neededValueKey };
      result.push(dataForKey);
      keyMap.set(neededValueKey, dataForKey);
    }
  }
  if (dataLength === 0) return result;
  let xAxisCorrection = telemetryArray[0][xAxis];//we want first value to be 0
  let keys = Array.from(keyMap.keys());
  let lastXAxisValue = -1;
  let dataIndex = 0;
  for (let i = 0; i < dataLength; i++) {
    const telemetry = telemetryArray[i];
    let xAxisValue = Math.round((telemetry[xAxis] - xAxisCorrection) / 1000);//using time
    if (lastXAxisValue === xAxisValue)
      continue;
    lastXAxisValue = xAxisValue;
    for (const key of keys) {
      keyMap.get(key).data[dataIndex] = [xAxisValue, telemetry[key]];
    }
    dataIndex++;
  }
  // Trim any unused preallocated space
  for (let i = 0; i < result.length; i++) {
    result[i].data.length = dataIndex;
  }
  return result;
};

export const isEmailValid = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const haveRole = (role: string): boolean => {
  const rights = localStorage.getItem('authorities');
  return rights && rights.toUpperCase().includes(role.toUpperCase());
};

export const formatVsPeers = (data: GaugeChartModel): PeersChartModel =>
  (data.validSubscription && data.peers !== undefined) || (!data.validSubscription && data.peers !== undefined)
    ? { color: MAIN_ACCENT_COLOR, ...data.peers }
    : undefined;

export const formatChartData = (chartType: ChartType, chartStats: GaugeChartModel, title?: string): GaugeChartModel =>
  ({
    title,
    chartType,
    vsPeers: formatVsPeers(chartStats),
    mainColor: MAIN_ACCENT_COLOR,
    ...chartStats
  });

export const isV3Api = (version: string): boolean => {
  if (!version) return false;
  const versionRegex = /(^3+\.\d+\.+\d*)/;
  return versionRegex.test(version);
};
