import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { InfoActions, OwnerActions, RiderActions } from '../actions';
import { AppState } from '../state';
import { ChargeBeeService, OwnerService } from '@carol-nx/services';
import { catchError, debounceTime, filter, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { verboseErrorMessage } from '@carol-nx/utils';
import { PaymentIntentWithBilling } from '@carol-nx/data';

@Injectable()
export class OwnerEffects {

  GetPrice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OwnerActions.GetPrice),
      switchMap((action) => this.ownerService.getPrice(action.bikeId)
        .pipe(
          switchMap(resp => {
            const action1 = OwnerActions.SetPrice({price: resp})
            return of(action1);
          }),
          catchError(err => of(InfoActions.SetCommonError({
            title: 'Error',
            message: verboseErrorMessage(err.error?.message)
          })))
        )
      )
    )
  );

  GetSubscription$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OwnerActions.GetSubscription),
      switchMap((action) => this.ownerService.getSubscription(action.bikeId)
        .pipe(
          switchMap(resp => {
            const action1 = OwnerActions.SetSubscription({subscription: resp});
            return of(action1);
          }),
          catchError(err => of(InfoActions.SetCommonError({
            title: 'Error',
            message: verboseErrorMessage(err.error?.message)
          })))
        )
      )
    )
  );

  //TODO conversion required
  createPaymentSource$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OwnerActions.CreatePaymentSource),
      switchMap(action => {
          window.postMessage({runProcessing: 'loadingPaymentIntent'}, '*');
        return this.ownerService.postPaymentIntent(action.bikeId, action.paymentCard.billing as PaymentIntentWithBilling, action.amount, action.currencyAmount)
            .pipe(
              switchMap(
                intent => {
                  window.postMessage({runProcessing: 'load3DSHandler'}, '*');
                  return this.chargeBeeService.load3DSHandler()
                    .pipe(
                      switchMap(threeDSHandler => {
                          window.postMessage({runProcessing: 'appendPaymentCard'}, '*');
                          return this.chargeBeeService.appendPaymentCard(threeDSHandler, intent, action.paymentCard, action.before3dsFunc).pipe(
                            switchMap(applyIntent => {
                              window.postMessage({runProcessing: 'createPaymentIntent'}, '*');
                              const action1 = OwnerActions.SuccessPaymentIntent({paymentIntent: intent});
                              return of(action1);
                              }
                            )
                          )
                        }
                      )
                    )
                }
              ),
              catchError(err => {
                let errText;
                if (err.error && err.error.detail) {
                  errText = err.error.detail;
                } else if (err.error && err.error.message) {
                  errText = err.error.message;
                } else if (err.displayMessage) {
                  errText = err.displayMessage;
                } else if (!window.navigator.onLine) {
                  errText = 'No internet connection';
                }
                else {
                  errText = err;
                }
                return of(OwnerActions.FailPaymentIntent({error: errText, detail: JSON.stringify(err)}));
              })
            )
        }
      )
    )
  );

  createSubscription$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OwnerActions.CreateSubscription),
      switchMap((action) => this.ownerService.postSubscription(action.bikeId, action.priceId, action.quantity, action.paymentIntent, action.flag3ds)
        .pipe(
          switchMap(resp => {
            const action1 = OwnerActions.SuccessSubscription({subscription: resp});
            return of(action1);
          }),
          catchError(err =>
          {
            let errText;
            if (err.error && err.error.detail) {
              errText = err.error.detail;
            } else if (err.error && err.error.message) {
              errText = err.error.message;
            } else if (err.message) {
              errText = err.message;
            } else {
              errText = err;
            }
            return of(OwnerActions.FailSubscription({error: errText, detail: JSON.stringify(err)}));
          }
          )
        )
      )
    )
  );

  getBikeCountry$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OwnerActions.GetBikeCountry),
      switchMap((action) => this.ownerService.getBikeCountry(action.bikeId)
        .pipe(
          switchMap(resp => {
            const action1 = OwnerActions.SetBikeCountry({country: resp});
            return of(action1);
          }),
          catchError(err => of(
            OwnerActions.FailBikeCountry(err),
            InfoActions.SetCommonError({
              title: 'Error',
              message: verboseErrorMessage(err.error?.message)
            })
          ))
        )
      )
    )
  );

  getNextPrice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OwnerActions.GetNextPrice),
      debounceTime(320),
      switchMap((action) => this.ownerService.getNextPrice(action.bikeId, action.priceId, action.quantity, action.countryId, action.postCode, action.city, action.address)
        .pipe(
          switchMap(resp => {
            const action1 = OwnerActions.SetPrice({price: resp})
            return of(action1);
          }),
          catchError(err => of(InfoActions.SetCommonError({
            title: 'Get price error',
            message: verboseErrorMessage(err.error?.message ? err.error?.message : err)
          })))
        )
      )
    )
  );

  removeRiderFromLicense$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OwnerActions.RemoveRiderFromLicense),
      switchMap(({riderId, licenseId}) =>
        this.ownerService.removeRiderFromLicense(riderId, licenseId)
          .pipe(
            switchMap(resp => of(RiderActions.RemoveOwnedRider({riderId}))),
            catchError(err => of(InfoActions.SetCommonError({
              title: 'Error',
              message: verboseErrorMessage(err.error?.message)
            })))
          )
      )
    )
  );

   getLicenseStatisticValues$ = createEffect(() =>
      this.actions$.pipe(
        ofType(OwnerActions.GetLicenseStatisticValues),
        filter(action => !!action && !!action.licenseId),
        switchMap(action => this.ownerService.getLicenseStatisticValues(action.licenseId)
          .pipe(
            switchMap(data => {
              const action1 = OwnerActions.SetLicenseStatisticValues({ stats: data });
              const action2 = OwnerActions.SetLicenseDetails({ licenseDetails: data.licenseDetails });
              return of(action1, action2);
            }),
            catchError(err => of(InfoActions.SetCommonError({
              title: 'Error',
              message: verboseErrorMessage(err.error?.message)
            })))
          )
        )
      )
    );


  getLicenseMonthlyActivity$ = createEffect(() =>
    this.actions$.pipe(ofType(OwnerActions.GetLicenseMonthlyActivity),
    filter(action => !!action && !!action.licenseId),
    switchMap((action => this.ownerService.getLicenseMonthlyRideCalendar(action.licenseId, action.month, action.year)
        .pipe(
          switchMap(data => data.datesWithRides &&
            of(OwnerActions.SetLicenseMonthlyActivity({licenseDatesWithRides: data.datesWithRides}))),
          )
        )
      )
    )
  );

  getLicenseLastActivity$ = createEffect(() =>
    this.actions$.pipe(ofType(OwnerActions.GetLicenseLastActivity),
      filter(action => !!action && !!action.licenseId),
      switchMap(action => this.ownerService.getLicenseMonthlyLastRides(action.licenseId)
        .pipe(
          switchMap(data => data.datesWithRides &&
            of(OwnerActions.SetLicenseMonthlyActivity({licenseDatesWithRides: data.datesWithRides}))),
        )
      )
    )
  );

  constructor(private actions$: Actions,
              private store: Store<AppState>,
              private ownerService: OwnerService,
              private chargeBeeService: ChargeBeeService) {
  }
}
