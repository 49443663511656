import { NgModule } from '@angular/core';
import { PowerInfoBoxComponent } from './power-info-box/power-info-box.component';
import { FooterComponent } from './footer/footer.component';
import { BookingsListComponent } from './bookings-list/bookings-list.component';
import { LoginLogoutNavItemComponent } from './login-logout-nav-item/login-logout-nav-item.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { RouterModule } from '@angular/router';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { SearchRiderModalComponent } from './modals/search-rider-modal/search-rider-modal.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MessageModalComponent } from './modals/message-modal/message-modal.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { SwitcherComponent } from './switcher/switcher.component';
import { DashboardCardsMainComponent, UiModule } from '@carol-nx/ui';
import { DashboardChartComponent } from './dashboard-chart/dashboard-chart.component';
import { DashboardCardsComponent } from './dashboard-cards/dashboard-cards.component';
import { RowSelectionDirective } from '../directives/row-selection.directive';
import { TableSelectionDirective } from '../directives/table-selection.directive';
import { TranslateModule } from '@ngx-translate/core';
import { HabitScoreCardComponent } from './habit-score-card/habit-score-card.component';
import { UtilsModule } from '@carol-nx/utils';
import { AvatarUsernameComponent } from './avatar-username/avatar-username.component';

const components = [
  PowerInfoBoxComponent,
  FooterComponent,
  BookingsListComponent,
  LoginLogoutNavItemComponent,
  SearchRiderModalComponent,
  MessageModalComponent,
  SwitcherComponent,
  DashboardChartComponent,
  DashboardCardsMainComponent,
  DashboardCardsComponent,
  RowSelectionDirective,
  TableSelectionDirective,
  HabitScoreCardComponent,
  AvatarUsernameComponent
];

@NgModule({
  declarations: [
    ...components
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    TranslateModule,

    MatTooltipModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatListModule,
    MatButtonModule,
    MatDialogModule,
    MatAutocompleteModule,

    UiModule,
    UtilsModule
  ],
  exports: [
    ...components
  ]
})
export class ComponentsModule {
}
