import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { UserModel } from '@carol-nx/data';

@Component({
  selector: 'avatar-username',
  templateUrl: './avatar-username.component.html',
  styleUrls: ['./avatar-username.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AvatarUsernameComponent {
  @Output()
  public onClick: EventEmitter<void> = new EventEmitter<void>();
  @Input()
  public rider: UserModel;
  @Input()
  public link: (string | number)[] | string;

  public onClickEvent(event: MouseEvent) {
    // to prevent follow link
    event.preventDefault();
    event.stopPropagation();
    this.onClick.emit();
  }
}
